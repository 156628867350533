import React, {useState, useEffect} from 'react';
// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function BusyPopup( { open = false }) {
    return (
        <Backdrop
            open={open}
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
            <CircularProgress color="dark" />
        </Backdrop>
    )
}

  
export default BusyPopup;