import React from 'react';
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MDButton from 'components/MDButton';
import TextField from 'components/Form/TextField';
import {Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";

import yourRep from "assets/partridge/Brysen.jpg";

const OwnerInfoPage = ({ values, onBack, onCompleted}) => {
    var state = {
        fullName: '',
        workPhone: '',
        mobilePhone: '',
        birthDate: '',
        percentOwnership: '',
        title: '',
        homeAddress1: '',
        homeAddress2: '',
        homeCity: '',
        homeState: '',
        homeZipCode: ''
    };

    if (values) {
        state = values;
    }

    const onSubmit = (values) => {
        console.log(values);
        if (onCompleted) {
            onCompleted(values);
        }
    };

    const isRequired = (message) => (value) => (!!value ? undefined : message);

    return (
        <Formik
            initialValues={state}
            onSubmit={async (values) => onSubmit(values)} 

        >
            {({ values, errors, touched, isSubmitting }) => (
            <Form>
                <Grid container>
                    <Grid item xs={12} lg={12} >
                    <MDBox lineHeight={0}>
                        <MDTypography variant="h5">Ownership Information</MDTypography>
                        <MDTypography variant="button" color="text">
                            Provide some details about the business owner applying for funding.
                        </MDTypography>
                    </MDBox>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <MDBox mt={1.625}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <MDBox>
                                        <MDTypography variant="h6">Business Address</MDTypography>
                                    </MDBox>
                                    <TextField
                                        name="homeAddress1"
                                        type="text"
                                        label="Address Line 1" 
                                    />
                                    <TextField
                                        name="homeAddress2"
                                        type="text"
                                        label="Address Line 2" 
                                    />
                                    <TextField
                                        name="homeCity"
                                        type="text"
                                        label="City" 
                                    />
                                    <TextField
                                        name="homeState"
                                        type="text"
                                        label="State" 
                                    />
                                    <TextField
                                        name="homeZipCode"
                                        type="text"
                                        label="Zip Code" 
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="fullName"
                                        type="text"
                                        label="Full Name" 
                                    />
                                    <TextField
                                        name="workPhone"
                                        type="text"
                                        label="Work Phone"
                                    />
                                    <TextField
                                        name="mobilePHone"
                                        type="text"
                                        label="Mobile Phone" 
                                    />
                                    <TextField
                                        name="birtDate"
                                        type="text"
                                        label="Date Of Birth" 
                                    />
                                    <TextField
                                        name="percentOwnership"
                                        type="text"
                                        label="Business Phone" 
                                    />
                                    <TextField
                                        name="title"
                                        type="text"
                                        label="Title" 
                                    />
                                </Grid>
                            </Grid>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          <MDButton variant="gradient" color="light" onClick={onBack}>
                            back
                          </MDButton>
                            <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                            >
                                Next
                            </MDButton>
                      </MDBox>
                    </Grid>
                </Grid>
            </Form>
            )}
        </Formik>
    )
}

export default OwnerInfoPage;