import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from "react-oidc-context";

import { MaterialUIControllerProvider } from "context";

const oidcConfig = {
    authority: process.env.REACT_APP_AUTH_ISSUER_URL,
    client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
    client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
    redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URL,
    token_request_extras: { resource: "vixo-applicant" },
    scope: "email profile openid",

};

const onSigninCallback = (user) => {
  console.log(user.access_token);
  console.log("Logged in!");
  console.log(user);
  window.location.href = "/";
}

function onSignoutCallback() {
  window.location.href = "https://www.vixolink.com";
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MaterialUIControllerProvider>
    <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback} onSignoutCallback={onSignoutCallback}>
      <App />
    </AuthProvider>
  </MaterialUIControllerProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
