import {create} from "zustand";
import { devtools, persist } from "zustand/middleware";

const brandingStore = (set, get) => ({
    heroImage: '',
    displayName: '',
    catchPhrase: '',
    tenantLogo: '',
    setHeroImage: (imageUrl) => {
        set((state) => ({
            heroImage: imageUrl,
        }));
    },
    setCatchPhrase: (phrase) => {
        set((state) => ({
            catchPhrase: phrase,
        }));
    },
    setDisplayName: (name) => {
        set((state) => ({
            displayName: name,
        }));
    },
    setAgentName: (memberName) => {
        set((state) => ({
            AgentName: memberName,
        }));
    },
    setTenantLogo: (tenantLogo) => {
        set((state) => ({
            tenantLogo: tenantLogo,
        }));
    }, 
});

const useBrandingStore = create(brandingStore);

export default useBrandingStore;