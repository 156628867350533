import React, {useState, useEffect} from 'react';
// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from 'components/MDButton';

function ErrorDialog( { error = null, open, handleClose }) {

    function renderTitle(error) {
        if (error !== null) {
            if (error?.response?.data?.title !== undefined) {
                return error.response?.data?.title;
            } else {
                return error.code;
            }
        }
        return "";
    };

    function renderMessage(error) {
        if (error !== null) {
            if (error?.response?.data?.detail !== undefined) {
                return error.response?.data?.detail;
            } else {
                return error.message;
            }
        }
        return "";
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {renderTitle(error)}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {renderMessage(error)}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <MDButton onClick={handleClose} autoFocus>
                Close
            </MDButton>
            </DialogActions>
        </Dialog>
    )
}

  
export default ErrorDialog;