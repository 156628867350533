import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CheckIcon from '@mui/icons-material/CheckCircle';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import yourRep from "assets/partridge/Brysen.jpg";

const SuccessPage = () => {
    const handleClose = () => {
        // Add any necessary logic to close the window
        window.close();
    };

    return (
        <MDBox>
            <MDBox width="82%" textAlign="center" mx="auto" my={4}>
                <MDTypography variant="body2" color="text" mb={3}>
                    Thank you for taking the time to fill that out. I'll be reaching out soon to help move this process forward.
                </MDTypography>
                <MDBox mb={1}>
                    <MDTypography variant="h5" fontWeight="regular">
                        Looking forward to doing business with you!
                    </MDTypography>
                </MDBox>
                <MDBox mt={3}>
                    <MDButton variant="gradient" color="info" onClick={handleClose}>
                        Close Window
                    </MDButton>
                </MDBox>
                <MDTypography variant="body2" color="text" mt={3}>
                    You can close this window, and our team will be in touch.
                </MDTypography>
            </MDBox>
        </MDBox>
    );
};

export default SuccessPage;

<userStyle>Normal</userStyle>