import React, { useState } from 'react';

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography';

import TodoWizard from 'components/TodoWizard';
import ContactInfoPage from './ContactInfo';
import BusinessInfoPage from './BusinessInfo';
import OwnerInfoPage from './OwnerInfo';
import ProfileInfoPage from './ProfileInfo'
import LoanAmountPage from './LoanAmount';
import AccountLinkPage from './AccountLink';
import VerifyIdentityPage from './VerifyIdentity';
import SuccessPage from './Success';
import BlankPage from './BlankPage';
import { Formik, Form } from 'formik';


function getSteps() {
    return ["Contact", "Business", "Ownership", "Profile", "Loan", "Accounts", "Identity"];
  }

function NewWizardPage() {
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();
    const isLastStep = activeStep === steps.length - 1;
    const [formValues, setFormValues] = useState([null, null, null, null, null, null, null]);

    const onBack = () => {
        console.log("Back button pressed");
        setActiveStep(activeStep - 1);
    };

    const onCompleted = (formUpdate) => {
        console.log("step completed");

        if (formValues.length > activeStep) {
            const updatedValues = formValues.map((item, index) => {
                if (index === activeStep) {
                    return formUpdate;
                }
            });

            setFormValues(updatedValues);
        }

        console.log("complete!");
        setActiveStep(activeStep + 1);
    };

    return (
        <MDBox py={3} mb={20} height="65vh">
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: { xs: 2, md: 6} }}>
          <Grid item xs={12} sm={12} lg={8}>
          <Card sx={{ height: "100%" }}>
          {activeStep <= 6 && (
            <MDBox mx={2} >
                <Stepper activeStep={activeStep} alternativeLabel sx={{display: { xs: 'none', md: 'flex' }}}>
                    {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                    ))}
                </Stepper>
            </MDBox>
          )}
        <Grid container spacing={3} alignItems="center" mb={2} pl={5} pr={5} width="100%">
            <Grid item xs={12} lg={12}>
                <MDBox mt={0.5} lineHeight={1}>
                    {activeStep === 0 && (
                        <ContactInfoPage values={formValues[0]} onBack={onBack} onCompleted={onCompleted} />
                    )}
                    {activeStep === 1 && (
                        <BusinessInfoPage values={formValues[1]} onBack={onBack} onCompleted={onCompleted} />
                    )}
                    {activeStep === 2 && (
                        <OwnerInfoPage values={formValues[2]} onBack={onBack} onCompleted={onCompleted} />
                    )}
                    {activeStep === 3 && (
                        <ProfileInfoPage values={formValues[3]} onBack={onBack} onCompleted={onCompleted} />
                    )}
                    {activeStep === 4 && (
                        <LoanAmountPage values={formValues[4]} onBack={onBack} onCompleted={onCompleted} />
                    )}
                    {activeStep === 5 && (
                        <AccountLinkPage values={formValues[4]} onBack={onBack} onCompleted={onCompleted} />
                    )}
                    {activeStep === 6 && (
                        <VerifyIdentityPage onBack={onBack} onCompleted={onCompleted} />
                    )}
                    {activeStep > 6 && (
                        <SuccessPage />
                    )}
                </MDBox>
            </Grid>
        </Grid>
        </Card>
        </Grid>
        </Grid>
        </MDBox>
    )
}

export default NewWizardPage;