import React, { useState, useEffect } from 'react';
import { ApplicantApiClient } from 'providers/ApplicantApiClient';
import { TenantApiClient } from 'providers/TenantApiClient';
import useBrandingStore from 'store/brandStore';

import Grid from "@mui/material/Grid";
import CircularProgress from '@mui/material/CircularProgress';

import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MDButton from 'components/MDButton';
import TextField from 'components/Form/TextField';
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from 'yup';



const contactInfoSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(1, 'First Name must be longer')
        .max(150, 'FIrst Name is too long.')
        .required('First Name is required'),
    lastName: Yup.string()
        .min(1, 'Last Name must be longer')
        .max(150, 'Last Name is too long')
        .required('Last Name is required'),
    email: Yup.string()
        .email('Invalid email')
        .required('Email is required'),
    mobilePhone: Yup.string()
        .matches(/^\+?\d{1,2}?[-\s]?\(?\d{3}\)?[-\s]?\d{3}[-\s]?\d{4}$/, 'Invalid phone number format')
        .required('Mobile Phone is required')
});

const formatPhoneNumber = (value) => {
    // Remove non-digit characters from the value
    const phoneNumber = value.replace(/\D/g, '');

    // Format the phone number
    if (phoneNumber.length === 0) {
        return '';
    }
    if (phoneNumber.length <= 3) {
        return phoneNumber;
    }
    if (phoneNumber.length <= 6) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};


const ContactInfoPage = ({ todoId, onBack, onCompleted}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [member, setMember] = useState(null);

    const [state, setState] = useState({
        firstName: "",
        lastName: "",
        email: "",
        mobilePhone: ""
    });
    const tenantLogo = useBrandingStore((state) => state.heroImage);

    useEffect(() => {
        console.log('fetch contact info for ' + todoId);
        ApplicantApiClient.get('api/applicant/contactInfo/' + todoId)
        .then((data) => {
            setState(data);
            setIsLoading(false);
            console.log(data);
        })
        .catch((error) => {

            setIsLoading(false);
        });

        //TenantApiClient.get('api/members/info')
        //    .then((tenant) => {
        //        setMember(tenant);
        //        setIsLoading(false);
           
        //    })
        //    .catch((error) => {

        //        setIsLoading(false);
          //  });
    }, []);

    const onSubmit = (values) => {
        ApplicantApiClient.put('api/applicant/contactInfo/' + todoId, values)
        .then((data) => {
            if (onCompleted) {
                onCompleted();
            }
        })
        .catch((error) => {
            console.error(error);
        })
    };
    
    if (isLoading) {
        return (
            <div>
                <CircularProgress color="dark" />
            </div>
        );
    } else {
    return (
        <Formik
            initialValues={state || {
                firstName: "",
                lastName: "",
                email: "",
                mobilePhone: ""
            }}
            validationSchema={contactInfoSchema}
            onSubmit={async (values) => onSubmit(values)}
        >
            {({ values, handleChange,  errors, touched, isSubmitting }) => (
            <Form>
                <MDBox>
                    <MDBox width="82%" textAlign="center" mx="auto" my={4}>
                        <MDTypography variant="body2" color="text" mb={3}>
                                Greetings! We are excited to help you with your application.
                        </MDTypography>
                        <MDBox mb={1}>
                        <MDTypography variant="h5" fontWeight="regular">
                            Let&apos;s start with the basic contact information.
                        </MDTypography>
                        </MDBox>
                    </MDBox>
                    <MDBox mt={2}>
                        <Grid container spacing={3}>
                                <Grid item xs={12} sm={4} container justifyContent="center">
                                    <MDBox position="relative" height="max-content" mx="auto">
                                        <MDAvatar src={tenantLogo} alt="your lending agent" size="xxl" shadow="md" />
                                        <br />
                                        <MDTypography variant="h5" fontWeight="regular">
                                        
                                            {member && member.name ? member.name : <>&nbsp;</>}
                                        </MDTypography>
                                    </MDBox>
                                </Grid>
                            <Grid item xs={12} sm={8}>
                                <MDBox mb={2}>
                                        <TextField
                                            name="firstName"
                                            type="text"
                                            label="First Name"
                                         
                                        />
                                    </MDBox>
                                    <MDBox mb={2}>
                                        <TextField
                                            name="lastName"
                                            type="text"
                                            label="Last Name"
                                            
                                        />
                                </MDBox>
                                <MDBox mb={2}>
                                    <TextField name="email" type="email" label="Email Address" />
                                </MDBox>
                                    <MDBox>
                                        <TextField
                                            name="mobilePhone"
                                            label="Mobile Phone"
                                            variant="outlined"
                                            value={formatPhoneNumber(values.mobilePhone)}
                                            onChange={(e) => {
                                                handleChange({
                                                    target: {
                                                        name: 'mobilePhone',
                                                        value: e.target.value.replace(/\D/g, ''),
                                                    },
                                                });
                                            }}
                                            
                                        />
                                    </MDBox>
                            </Grid>
                        </Grid>
                    </MDBox>
                    <MDBox mt={2} width="100%" display="flex" justifyContent="right">
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                          >
                            Get Started
                          </MDButton>
                        </MDBox>
                </MDBox>
            </Form>
            )}
        </Formik>
    )}
}

export default ContactInfoPage;