import React from 'react';
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MDButton from 'components/MDButton';
import TextField from 'components/Form/TextField';
import {Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";

import yourRep from "assets/partridge/Brysen.jpg";

const ProfileInfoPage = ({ values, onBack, onCompleted}) => {
    var state = {
        entityType: '',
        industry: '',
    };

    if (values) {
        state = values;
    }

    const onSubmit = (values) => {
        console.log(values);
        if (onCompleted) {
            onCompleted(values);
        }
    };

    const isRequired = (message) => (value) => (!!value ? undefined : message);

    return (
        <Formik
            initialValues={state}
            onSubmit={async (values) => onSubmit(values)} 

        >
            {({ values, errors, touched, isSubmitting }) => (
            <Form>
                <Grid container>
                    <Grid item xs={12} lg={12} >
                    <MDBox lineHeight={0}>
                        <MDTypography variant="h5">Business Profile</MDTypography>
                        <MDTypography variant="button" color="text">
                            Provide some additional profile information about the business.
                        </MDTypography>
                    </MDBox>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <MDBox mt={1.625}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="entityType"
                                        type="text"
                                        label="Entity Type" 
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="industry"
                                        type="text"
                                        label="Industry" 
                                    />
                                </Grid>
                            </Grid>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                          <MDButton variant="gradient" color="light" onClick={onBack}>
                            back
                          </MDButton>
                            <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                            >
                                Next
                            </MDButton>
                      </MDBox>
                    </Grid>
                </Grid>
            </Form>
            )}
        </Formik>
    )
}

export default ProfileInfoPage;