import React, { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";

import Navbar from "components/Navbar";

import useBrandingStore from "store/brandStore";
import DemoPage from "pages/Demo/DemoMode";
import NewWizardPage from "pages/Demo/NewWizard";
import FollowUpWizardPage from "pages/Demo/FollowUpWizard";
import { TenantApiClient } from "providers/TenantApiClient";

import WizardPage from "pages/Wizard";

const router = createBrowserRouter([
    {
        path: "/",
        element: <WizardPage />,
    },
    {
        path: "/demo",
        element: <NewWizardPage />,
    },
]);

function App() {
    const catchPhrase = useBrandingStore((state) => state.catchPhrase);
    const displayName = useBrandingStore((state) => state.displayName);
    const tenantLogo = useBrandingStore((state) => state.heroImage); // Access logo from Zustand
    const setTenantLogo = useBrandingStore((state) => state.setHeroImage);
    const setDisplayName = useBrandingStore((state) => state.setDisplayName);
    const setHeroImage = useBrandingStore((state) => state.setHeroImage);
    const queryParams = new URLSearchParams(window.location.search);

    const invitation = queryParams.get("invitation");

    const auth = useAuth();

    useEffect(() => {
        if (auth.isAuthenticated) {
            // Fetch tenant logo URL and display name once the user is authenticated
            TenantApiClient.get("api/tenants/logo")
                .then((response) => {
                    console.log("API Response:", response);
                    if (response.logo) {
                        setTenantLogo(response.logo); // Store the fetched logo URL in Zustand
                        setHeroImage(response.logo); // Set the hero image (logo) in Zustand (for backwards compatibility
                    }
                    if (response.displayName) {
                        setDisplayName(response.displayName); // Set the display name if provided
                    }
                })
                .catch((error) => {
                    console.error("Error fetching tenant logo:", error);
                });
        }
    }, [auth.isAuthenticated, setTenantLogo, setDisplayName]);

    useEffect(() => {
        // Log to check if logo and displayName are updating
        console.log("Tenant Logo URL:", tenantLogo);
        console.log("Display Name:", displayName);
    }, [tenantLogo, displayName]);

    switch (auth.activeNavigator) {
        case "signInSilent":
            return "<div>Signing you in...</div>";
        case "signoutRedirect":
            return "<div>Signing you out...</div>";
    }

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (auth.error) return <div>Error: {auth.error.message}</div>;

    if (auth.isAuthenticated) {
        localStorage.setItem("token", auth?.user?.access_token ?? "");
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Navbar
                    brandImage={tenantLogo} // Use the URL directly from Zustand state
                    brandDisplayName={displayName}
                    brandCatchPhrase={catchPhrase}
                />
                <RouterProvider router={router} />
            </ThemeProvider>
        );
    } else {
        auth.signinRedirect({ extraQueryParams: { invitation: invitation } });
    }
}

export default App;
