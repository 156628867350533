import React, {useState} from 'react';
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MDButton from 'components/MDButton';
import TextField from 'components/Form/TextField';
import {Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";

import yourRep from "assets/partridge/Brysen.jpg";

const ContactInfoPage = ({ values, onBack, onCompleted}) => {
    var state = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
    };

    if (values) {
        state = values;
    }

    const onSubmit = (values) => {
        console.log(values);
        if (onCompleted) {
            onCompleted(values);
        }
    };

    const isRequired = (message) => (value) => (!!value ? undefined : message);

    return (
        <Formik
            initialValues={state}
            onSubmit={async (values) => onSubmit(values)} 

        >
            {({ values, errors, touched, isSubmitting }) => (
            <Form>
                <MDBox>
                    <MDBox width="82%" textAlign="center" mx="auto" my={4}>
                        <MDTypography variant="body2" color="text" mb={3}>
                            Hi, I'm your lending agent, Brysen.  <br/>Thank you for reaching out to Partridge Lending.  We just need a little more information to begin your loan application process.
                        </MDTypography>
                        <MDBox mb={1}>
                        <MDTypography variant="h5" fontWeight="regular">
                            Let&apos;s start with the basic contact information.
                        </MDTypography>
                        </MDBox>
                    </MDBox>
                    <MDBox mt={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4} container justifyContent="center">
                                <MDBox position="relative" height="max-content" mx="auto">
                                    <MDAvatar src={yourRep} alt="your lending agent" size="xxl" shadow="md" />
                                    <br/>
                                    <MDTypography variant="h6">
                                        Brysen Partridge
                                    </MDTypography>
                                    <MDTypography variant="button">
                                        President/CEO
                                    </MDTypography>
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} sm={8}>
                                <MDBox mb={2}>
                                    <TextField
                                        name="firstName"
                                        type="text"
                                        label="First Name"
                                        validate={isRequired('First Name is required')}
                                    />
                                </MDBox>
                                <MDBox mb={2}>
                                    <TextField
                                        name="lastName"
                                        type="text"
                                        label="Last Name" 
                                        validate={isRequired('Last Name is required')}
                                    />
                                </MDBox>
                                <MDBox mb={2}>
                                    <TextField name="email" type="email" label="Email Address" />
                                </MDBox>
                                <MDBox>
                                    <TextField name="phone" type="phone" label="Mobile Phone" />
                                </MDBox>
                            </Grid>
                        </Grid>
                    </MDBox>
                    <MDBox mt={2} width="100%" display="flex" justifyContent="right">
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            variant="gradient"
                            color="dark"
                          >
                            Get Started
                          </MDButton>
                        </MDBox>
                </MDBox>
            </Form>
            )}
        </Formik>
    )
}

export default ContactInfoPage;