import React from 'react';
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MDButton from 'components/MDButton';
import TextField from 'components/Form/TextField';
import {Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";

import yourRep from "assets/partridge/Brysen.jpg";

const BusinessInfoPage = ({ values, onBack, onCompleted}) => {
    var state = {
        legalName: '',
        dbaName: '',
        federalId: '',
        dateStarted: '',
        businesPhone: '',
        website: '',
        businessEmail: '',
        businessAddress1: '',
        businessAddress2: '',
        businessCity: '',
        businessState: '',
        businessZipCode: ''
    };

    if (values) {
        state = values;
    }

    const onSubmit = (values) => {
        console.log(values);
        if (onCompleted) {
            onCompleted(values);
        }
    };

    const isRequired = (message) => (value) => (!!value ? undefined : message);

    return (
        <Formik
            initialValues={state}
            onSubmit={async (values) => onSubmit(values)} 

        >
            {({ values, errors, touched, isSubmitting }) => (
            <Form>
                <Grid container>
                    <Grid item xs={12} lg={12} >
                    <MDBox lineHeight={0}>
                        <MDTypography variant="h5">Business Information</MDTypography>
                        <MDTypography variant="button" color="text">
                            Provide some details about the business looking for funding.
                        </MDTypography>
                    </MDBox>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <MDBox mt={1.625}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <MDBox>
                                        <MDTypography variant="h6">Home Address</MDTypography>
                                    </MDBox>
                                    <TextField
                                        name="businessAddress1"
                                        type="text"
                                        label="Address Line 1" 
                                    />
                                    <TextField
                                        name="businessAddress2"
                                        type="text"
                                        label="Address Line 2" 
                                    />
                                    <TextField
                                        name="businessCity"
                                        type="text"
                                        label="City" 
                                    />
                                    <TextField
                                        name="businessState"
                                        type="text"
                                        label="State"
                                    />
                                    <TextField
                                        name="businessZipCode"
                                        type="text"
                                        label="Zip Code" 
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="legalName"
                                        type="text"
                                        label="Legal Business Name" 
                                    />
                                    <TextField
                                        name="dbaName"
                                        type="text"
                                        label="Doing Business As"
                                    />
                                    <TextField
                                        name="federalId"
                                        type="text"
                                        label="Federal ID (EIN)" 
                                    />
                                    <TextField
                                        name="dateStarted"
                                        type="text"
                                        label="Date Started" 
                                    />
                                    <TextField
                                        name="businessPhone"
                                        type="text"
                                        label="Business Phone" 
                                    />
                                    <TextField
                                        name="website"
                                        type="text"
                                        label="Website" 
                                    />
                                    <TextField
                                        name="businessEmail"
                                        type="text"
                                        label="Business Contact Email" 
                                    />
                                </Grid>
                            </Grid>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                            <MDButton variant="gradient" color="light" onClick={onBack}>
                                back
                            </MDButton>
                            <MDButton
                                type="submit"
                                variant="gradient"
                                color="dark"
                            >
                                Next
                            </MDButton>
                      </MDBox>
                    </Grid>
                </Grid>
            </Form>
            )}
        </Formik>
    )
}

export default BusinessInfoPage;