import React, { useState, useEffect } from 'react';
import { ApplicantApiClient } from '../providers/ApplicantApiClient';

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography';

import TodoWizard from 'components/TodoWizard';
import ContactInfoPage from './ContactInfo';
import FinicityPage from './Finicity';
import PlaidPage from './Plaid';
import SuccessPage from './Demo/Success';
import { Formik, Form, setIn } from 'formik';
import ErrorDialog from 'components/ErrorDialog';
import BusyPopup from 'components/BusyPopup';


function WizardPage() {
    const [todoItems, setTodoItems] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleErrorClose = () => {
        setError(null);
    };

    const [isDone, setIsDone] = useState(false);
    const [isInitialized, setInitialized] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [activeTodoItem, setActiveTodoItem] = useState(null);
    const [formValues, setFormValues] = useState([null, null, null, null, null, null, null]);

    const refreshTodoItems = () => {
        setIsLoading(true);
        ApplicantApiClient.get('/api/applicant/todoitems')
            .then((data) => {
                setTodoItems(data);

                var activeItem = null;
                let todoIdx = 0;
                data.map((todoItem) => {
                    
                    if (!todoItem.isComplete && !activeItem) {
                        setActiveTodoItem(todoItem);
                        setActiveStep(todoIdx);
                        activeItem = todoItem;
                    }
                    todoIdx++;
                });
                setIsLoading(false);
                setInitialized(true);

                if (activeItem == null) {
                    setIsDone(true);
                    setActiveStep(todoItems.length);
                    setActiveTodoItem(null);
                }

                console.log(activeStep);
            })
            .catch((error) => {
                setIsLoading(false);
                console.error(error);
                setError(error);
            });
    };

    useEffect(() => {
        refreshTodoItems();
    }, []);

    const onBack = () => {
        console.log("Back button pressed");
    };

    const onCompleted = () => {
        refreshTodoItems();
    };

    return (
        <MDBox py={3} mb={20} height="65vh">
            <BusyPopup open={isLoading} />
            <ErrorDialog
                error={error}
                open={error !== null}
                handleClose={handleErrorClose}
            />
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: { xs: 2, md: 6} }}>
            <Grid item xs={12} sm={12} lg={8}>
                <Card sx={{ height: "100%" }}>
                {isInitialized && (
                    <MDBox mx={2} >
                        <Stepper activeStep={activeStep} alternativeLabel sx={{display: { xs: 'none', md: 'flex' }}}>
                            {todoItems.map((todoItem) => (
                            <Step key={todoItem.id}>
                                <StepLabel>{todoItem.title}</StepLabel>
                            </Step>
                            ))}
                            <Step key="Done">
                                <StepLabel>Done</StepLabel>
                            </Step>
                        </Stepper>
                    </MDBox>
                )}
                {isInitialized && (
                    <Grid container spacing={3} alignItems="center" mb={2} pl={5} pr={5} width="100%">
                        <Grid item xs={12} lg={12}>
                            <MDBox mt={0.5} lineHeight={1}>
                            {activeTodoItem && (
                                <>
                                    {activeTodoItem.definitionId === "ContactInfo" && (
                                        <ContactInfoPage todoId={activeTodoItem.todoId} onBack={onBack} onCompleted={onCompleted} />
                                    )}
                                    {activeTodoItem.definitionId === "Finicity" && (
                                        <FinicityPage todoId={activeTodoItem.todoId} onBack={onBack} onCompleted={onCompleted} />
                                    )}
                                    {activeTodoItem.definitionId === "Plaid" && (
                                        <PlaidPage todoId={activeTodoItem.todoId} onBack={onBack} onCompleted={onCompleted} />
                                    )}
                                </>
                            )}
                            {isDone && (
                                <SuccessPage />
                            )}
                            </MDBox>
                        </Grid>
                    </Grid>
                )}
                </Card>
            </Grid>
        </Grid>
        </MDBox>
    )
}

export default WizardPage;