import axios, { AxiosResponse } from 'axios';

const instance = axios.create({
    //baseURL: 'https://localhost:7217/',
    baseURL: process.env.REACT_APP_TENANT_API,

    timeout: 60000
});



instance.interceptors.request.use((config) => {
    config.headers['Content-Type'] = 'application/json';
    config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
    return config;
});

const responseBody = (response) => response.data;

const requests = {
    get: (url) => instance.get(url).then(responseBody),
    post: (url, body) => instance.post(url, body).then(responseBody),
    put: (url, body) => instance.put(url, body).then(responseBody),
    delete: (url) => instance.delete(url).then(responseBody),
}

export const TenantApiClient = requests;